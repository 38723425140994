import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, updateProfileImag } from '../../../services/api/myProfileApis';
import { toast } from 'react-toastify'
import ImagerCropper from '../../../components/common/ImageCropper/ImagerCropper';
import { updateUserData } from '../../../redux/reducers/authSlice';



const DetailsBanner = ({ setEditProfile, editProfile }) => {
    const dispatch = useDispatch()
    const inputImgRef = useRef(null)
    const { user } = useSelector((state) => state.auth)
    const [imgFile, setImgFile] = useState(null)
    const handleProfileImage = async (e) => {
        const file = e.target.files[0];
        if (file?.type?.includes('image')) {
            if (file?.size > 5242880) {
                toast.error('Image size should be less than 5MB')
            }
            else {
                setImgFile(file)
            }
        }
        else {
            toast.error('Unsupported file format')
        }
    }

    const changeProfilePhoto = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        let bodyData = formData
        let data = await updateProfileImag(bodyData)
        if (data) {
            setImgFile(null)
            let userData = await getProfile()
            dispatch(updateUserData(userData?.data.userDetails))
            //update user here
        }
    }
    return (
        <section className="details-banner-wrap common-padd">
            <div className="container">
                <div className="details-banner-main">
                    <div className="details-banner-img">
                        <img src="images/details-banner.jpg" alt="" />
                    </div>
                    <div className="details-banner-content">
                        <div className='profile-main col-lg-10'>
                            <div className="details-profile profile-image">
                                <img src={!!user?.profile_image ? user?.profile_image : "images/profile-img-2.png"} alt="" />
                                <div className='overlay-image'>
                                    <input ref={inputImgRef} type='file' onChange={handleProfileImage} accept="image/gif, image/jpeg, image/png" />
                                    <FontAwesomeIcon icon={["far", "edit"]} color='white' />
                                </div>
                            </div>
                            <div className='profile-details'>
                                <h4>{user?.first_name} {user?.last_name}</h4>
                                <h7>{user?.email}</h7>
                                <p>{user?.about}</p>
                            </div>
                        </div>

                        {!editProfile && <div className='col-lg-2' onClick={() => setEditProfile(true)}>
                            <div className='edit-main'>
                                <img src="images/Vector.svg" alt="" />
                                <p>edit profile</p>
                            </div>
                        </div>}
                    </div>

                </div>

            </div>
            {!!imgFile && <ImagerCropper inputImgRef={inputImgRef} src={URL.createObjectURL(imgFile)} changeProfilePhoto={changeProfilePhoto} setImgFile={setImgFile} />}
        </section>
    );
};





export default DetailsBanner;

export const REDUX_PERSIST_KEY = "bi-reports"

export const NODE_ENV = "development";
// export const NODE_ENV = "production";

// export const API_URL =
//     NODE_ENV === "production"
//         ? "https://api.bireports.today/v1"
//         : NODE_ENV === "development"
//             ? "https://api-bi-reports.weavers-web.com/v1"
//             : "http://localhost:4000/v1";
export const API_URL = process.env.REACT_APP_API_ENDPOINT
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import i18Lang from "../../constants/i18Lang";
import { API_URL } from "../../config";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en", // Set the default language
    fallbackLng: "en", // Fallback language if translation for selected locale is not available
    backend: {
      loadPath:
        `${API_URL}/page-content/get-page-contents/en`, // Endpoint to fetch translations from the backend
    },
  });

//this is for from local

// i18n.use(initReactI18next).init({
//   lng: "en", // Set the default language
//   fallbackLng: "en", // Fallback language if translation for selected locale is not available
//   resources: {
//     en: {
//       translation: i18Lang.en,
//     },
//   },
// });

export default i18n;

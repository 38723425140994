import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';


const AboutAboutUs = () => {
  const { t } = useTranslation();

  return (
    <section className="about-sec position-relative common-padd">
      <div className="container">
        <div className="sec-heading">
          <h5>{t("cms_text_about_aboutUs001")}</h5>
          <h2>
            {t("cms_text_about_aboutUs002")}{" "}
            <span className="sky-text">{t("cms_text_about_aboutUs003")}</span>
          </h2>
          <p>
            {t("cms_text_about_aboutUs004")}
          </p>
        </div>
        {/* <NavLink to="/" className="round-btn"> read more</NavLink> */}

      </div>
    </section>
  );
};




export default AboutAboutUs;

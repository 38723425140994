import React, { useState } from 'react'
import DetailsBanner from './DetailsBanner'
import ProfileDetails from './ProfileDetails'

const MydetailsIndex = () => {
    const [editProfile,setEditProfile]=useState(false)
    return (
        <div>
            <DetailsBanner setEditProfile={setEditProfile} editProfile={editProfile}/>
            {editProfile && <ProfileDetails setEditProfile={setEditProfile} editProfile={editProfile}/>}
        </div>
    )
}

export default MydetailsIndex
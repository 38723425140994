import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { userLogin, userLoginWithOtp } from '../../services/api/authApis';
import { toast } from 'react-toastify';
const initialState = {
    user: null,
    isFetching: false,
    isError: false,
    isLogin: false,
    error: null,
}
export const signInWithOtp = createAsyncThunk(
    "auth/signIn",
    async (values, { rejectWithValue }) => {
        try {
            const data = await userLoginWithOtp(values);
            if (data?.code !== 200) return rejectWithValue(data);
            return data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            const { user } = action.payload
            state.user = user;
        },
        updateUserData: (state, action) => {
            state.user = action?.payload;
        },
        resetAuth: (state) => {
            state.user = null;
            state.isFetching = false;
            state.isError = false;
            state.isLogin = false;
            state.error = null;
        }
    },
    extraReducers: {
        [signInWithOtp.pending]: (state) => {
            state.isFetching = true;
            state.isLogin = false;
            state.isError = false;
            return state;
        },
        [signInWithOtp.fulfilled]: (state, { payload }) => {
            if (payload.data.user?.status === 'not_approved') {
                toast.warning('Your account is not approved by admin')
            }
            else {
                state.isLogin = true;
                state.user = payload.data.user;
                state.isFetching = false;
                state.isError = false;
                state.error = null;
                return state;
            }
        },
        [signInWithOtp.rejected]: (state, { payload }) => {
            state.isLogin = false;
            state.user = null;
            state.isFetching = false;
            state.isError = true;
            state.error = payload;
            return state;
        },
    },
});

// this is for dispatch
export const { updateUser, updateUserData, resetAuth } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
import React, { useEffect, useState } from 'react'
import ServiceBanner from './ServiceBanner'
import ServiceCompanies from './ServiceCompanies'
import ServiceService from './ServiceService'
import { dynamicDataSectionVise } from '../../../services/api/dynamicApi'

const ServicesIndex = () => {
  const [images, setImages] = useState()
  useEffect(() => {
    gettingDynamicData();
  }, []);
  const gettingDynamicData = async () => {
    let payloadData = {
      "section_ids": [
        "cms_section_service_serviceServices",
        "cms_section_service_serviceCompanies",
        "cms_section_service_banner"

      ]
    }
    let dataGet = await dynamicDataSectionVise(payloadData);
    setImages(dataGet?.data?.pageContentImages)
  };
  return (
    <div>
      <ServiceBanner images={images} />
      <ServiceCompanies />
      <ServiceService />
    </div>


  )
}

export default ServicesIndex
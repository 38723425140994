import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import homeBannerImage from '../../../imagesForShowingError/home-banner.jpg'



const AboutBanner = ({ images }) => {
  const [srcImage, setSrcImage] = useState()
  useEffect(() => {
    setSrcImage(images?.cms_image_about_banner001)
  }, [images])
  const { t } = useTranslation();
  const replaceImage = (error) => {
    error.target.src = homeBannerImage;
  };
  return (
    <section className="home-banner-section inner-banner position-relative">
      <div className="banner-img-wrap">
        {/* <img src={srcImage?srcImage :"images/home-banner.jpg" }alt="" /> */}
        <img src={srcImage} onError={replaceImage} alt="" />
      </div>
      <div className="banner-text-wrap">
        <div className="container">
          <div className="inner-banner-text">
            <h1>{t("cms_text_about_aboutUsBanner001")}</h1>
          </div>
        </div>
      </div>
    </section>
  );
};




export default AboutBanner;

import React, { useEffect, useState } from 'react'
import AboutBanner from './AboutBanner'
import AboutAboutUs from './AboutAboutUs'
import AboutProduct from './AboutProduct'
import { dynamicDataSectionVise } from '../../../services/api/dynamicApi'

const AboutIndex = () => {
  const [images, setImages] = useState()
  useEffect(() => {
    gettingDynamicData();
  }, []);
  const gettingDynamicData = async () => {
    let payloadData = {
      "section_ids": [
        "cms_section_about_aboutProducts",
        "cms_section_about_aboutUs",
        "cms_section_about_aboutUsBanner",

      ]
    }
    let dataGet = await dynamicDataSectionVise(payloadData);
    setImages(dataGet?.data?.pageContentImages)
  };
  return (
    <>
      <AboutBanner images={images} />
      <AboutAboutUs />
      <AboutProduct images={images} />
    </>
  )
}

export default AboutIndex
import { useSelector } from 'react-redux';
import Loading from './components/common/Loading';
import RouteIdentifier from './routing/RouteIdentifier';
import routes from './routing/router';

function App() {
  const { loading } = useSelector((state) => state.loading);
  return (
    <>
      {loading &&
        <Loading />
      }
      <RouteIdentifier
        routes={routes}
      />
    </>
  );
}

export default App;

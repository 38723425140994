import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const NoContentPage = () => {
    const { user } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/')
    }, [])
    return (
        <div>NoContentPage</div>
    )
}

export default NoContentPage
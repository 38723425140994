import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';



const HomeChooseUs = ({ images }) => {
  const [srcImage, setSrcImage] = useState()
  useEffect(() => {
    setSrcImage(images?.cms_image_home_choose_us_001)
  }, [images])
  const { t } = useTranslation();
  return (
    <section
      className="choose-us position-relative common-padd"
      style={{ backgroundImage: srcImage ? srcImage : 'url("images/comfort.jpg")' }}
    >
      <div className="container">
        <div className="sec-heading">
          <h5>{t("cms_text_home_choose001")}</h5>
          <h2>
            {" "}
            <strong>{t("cms_text_home_choose002")}</strong> {t("cms_text_home_choose003")}
          </h2>
          <p>
            {t("cms_text_home_choose004")}
          </p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="choose-col-wrap">
              <div className="choose-col-img">
                <img src="images/icon1.png" alt="" />
              </div>
              <h4>{t("cms_text_home_choose005")}</h4>
              <p>
                {t("cms_text_home_choose006")}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="choose-col-wrap">
              <div className="choose-col-img">
                <img src="images/icon2.png" alt="" />
              </div>
              <h4>{t("cms_text_home_choose007")}</h4>
              <p>
                {t("cms_text_home_choose008")}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="choose-col-wrap">
              <div className="choose-col-img">
                <img src="images/icon3.png" alt="" />
              </div>
              <h4>{t("cms_text_home_choose009")}</h4>
              <p>
                {t("cms_text_home_choose010")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};





export default HomeChooseUs;

import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProfileValidator } from "../../../services/formValidator/myProfileFormValidator";
import { getProfile, updateProfile } from "../../../services/api/myProfileApis";
import { updateUserData } from "../../../redux/reducers/authSlice";

const ProfileDetails = ({ setEditProfile, editProfile }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const editProfileFormik = useFormik({
        initialValues: myProfileValidator(user, 'initialValues'),
        validationSchema: myProfileValidator(user, 'validationSchema'),
        onSubmit: async (values) => {
            let bodyData = {
                first_name: values?.first_name,
                last_name: values?.last_name,
                about: values?.about,
            }
            let data = await updateProfile(bodyData)
            if (data) {
                let userData = await getProfile()
                dispatch(updateUserData(userData?.data.userDetails))
                setEditProfile(false)
            }
        }
    })
    const closeEditProf = () => {
        setEditProfile(false)
    }
    return (
        <section className="pr-details common-padd pt-0">
            <div className="container">
                <div className="pr-details-box">
                    <h2>my details</h2>
                    <div className="contact-details-right-wrap">
                        <div className="Contact-with-us">
                            <form onSubmit={editProfileFormik?.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>First name</label>
                                            <input
                                                onChange={editProfileFormik?.handleChange}
                                                value={editProfileFormik?.values?.first_name}
                                                type="text"
                                                placeholder="First name"
                                                id="first_name"
                                                name="first_name"
                                                className="form-control"
                                            />
                                            <p className='form-error-message'>{editProfileFormik.touched.first_name &&
                                                editProfileFormik.errors.first_name}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Last name</label>
                                            <input
                                                onChange={editProfileFormik?.handleChange}
                                                value={editProfileFormik?.values?.last_name}
                                                type="text"
                                                placeholder="Last name"
                                                id="last_name"
                                                name="last_name"
                                                className="form-control"
                                            />
                                            <p className='form-error-message'>{editProfileFormik.touched.last_name &&
                                                editProfileFormik.errors.last_name}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label>Email address</label>
                                            <input
                                                disabled
                                                onChange={editProfileFormik?.handleChange}
                                                value={editProfileFormik?.values?.email}
                                                type="email"
                                                placeholder="Email address"
                                                id="email"
                                                name="email"
                                                className="form-control"
                                            />
                                            <p className='form-error-message'>{editProfileFormik.touched.email &&
                                                editProfileFormik.errors.email}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label>About</label>
                                            <textarea
                                                onChange={editProfileFormik?.handleChange}
                                                value={editProfileFormik?.values?.about}
                                                type="text"
                                                placeholder="About yourself"
                                                id="about"
                                                name="about"
                                                className="form-control"
                                            />
                                            <p className='form-error-message'>{editProfileFormik.touched.about &&
                                                editProfileFormik.errors.about}</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-2" onClick={closeEditProf}>
                                        <div className="form-group">
                                            <button type="button" className="btn">
                                                cancel
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn">
                                                save changes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProfileDetails;

import * as Yup from "yup";


/**
 * Signup form validator for signup
 */
export const signupFromValidatorUser = {
    initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
        firstName: Yup.string()
            .matches(/^[a-zA-Z0-9\s]+$/, 'Only uppercase letters, lowercase letters, and numbers are allowed.')
            .required("Name field can not be empty")
            .min(3, "Must be 3 characters")
            .max(20, "Max can be 30 characters"),
        lastName: Yup.string()
            .matches(/^[a-zA-Z0-9\s]+$/, 'Only uppercase letters, lowercase letters, and numbers are allowed.')
            .required("Name field can not be empty")
            .min(3, "Must be 3 characters")
            .max(20, "Max can be 30 characters"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Email field cannot be empty"),
        password: Yup.string()
            .required("Password field is Required")
            .matches(/^(?!.*\s)/, "Spaces are not allowed")
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
            ),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), ""], "Password must match")
            .required("Confirm password field is required"),
    }),
};

/**
 * Login form validator for login
 */
export const loginFromValidator = {
    initialValues: {
        email: "",
        password: "",
    },
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address")
            .trim()
            .required("Email field cannot be empty"),
        password: Yup.string()
            .min(8, "Must be 8 or more than 8 characters")
            .required("Password field is Required")
            .matches(/^(?!.*\s)/, "Spaces are not allowed")
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
            ),
    }),
};

/**
 * forgotpassword form validator for forgot password and confirm password
 */
export const forgotpasswordFromValidator = {
    initialValues: {
        password: "",
        confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
        password: Yup.string()
            .required("Password field is Required")
            .matches(/^(?!.*\s)/, "Spaces are not allowed")
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
            ),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), ""], "Password must match")
            .required("Confirm password field is required"),
    }),
}
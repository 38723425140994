import React from 'react'
import VisitorHeader from '../components/common/Headers/VisitorHeader'
import VisitorFooter from '../components/common/Footers/VisitorFooter'
import { useSelector } from 'react-redux'
import UserHeader from '../components/common/Headers/UserHeader'
// import { Route, Routes } from 'react-router-dom'

const VisitorLayout = ({ children }) => {
    const { user } = useSelector(state => state.auth)
    window.scrollTo(0, 0)
    return (
        <div>
            {
                user?.role ?
                    <UserHeader />
                    :
                    <VisitorHeader />
            }
            <div>
                {children}
            </div>
            <VisitorFooter />
        </div>
    )
}

export default VisitorLayout
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import homeBannerImage from '../../../imagesForShowingError/home-banner.jpg'
const HomeBanner = ({ images }) => {
  const { t } = useTranslation();
  const [srcImage, setSrcImage] = useState()
  useEffect(() => {
    setSrcImage(images?.cms_image_home_banner001)
  }, [images])
  const replaceImage = (error) => {
    error.target.src = homeBannerImage;
  };

  return (
    <section className="home-banner-section position-relative">
      <div className="banner-img-wrap">


        <img src={srcImage} onError={replaceImage} alt="" />
      </div>
      <div className="banner-text-wrap">
        <div className="container">
          <div className="banner-heading">
            <h1>{t("cms_text_home_banner001")}</h1>
            {/* <div className="scroll-down">

              <NavLink to="/"> <img src="images/scroll-down.png" alt="" /></NavLink>
            </div> */}
          </div>
          <div className="banner-text">
            <p>
              {t("cms_text_home_banner002")}
            </p>
            <NavLink to="/contactus" className="round-btn"> {t("cms_text_home_banner003")}</NavLink>

          </div>
        </div>
      </div>
    </section>
  );
};




export default HomeBanner;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { signupFromValidatorUser } from '../../../services/formValidator/authFormValidator'
import { signUp } from '../../../services/api/authApis'
import { useTranslation } from 'react-i18next'
import { dynamicDataSectionVise } from '../../../services/api/dynamicApi'
import signUpPageImage from '../../../imagesForShowingError/images6.png'

const SignUp = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const signupFormik = useFormik({
        initialValues: signupFromValidatorUser.initialValues,
        validationSchema: signupFromValidatorUser?.validationSchema,
        onSubmit: async (values) => {
            let signupData = await signUp(values)
            if (signupData?.code === 201) {
                navigate('/login')
            }
        }
    })
    const [images, setImages] = useState()
    useEffect(() => {
        gettingDynamicData();
    }, []);
    const gettingDynamicData = async () => {
        let payloadData = {
            "section_ids": [
                "cms_section_sign_up",

            ]
        }
        let dataGet = await dynamicDataSectionVise(payloadData);
        setImages(dataGet?.data?.pageContentImages)
    };
    const replaceImage = (error) => {
        error.target.src = signUpPageImage;
    };
    return (
        <div className='loginwrap'>
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-lg-5 p-0">
                        <div className='auth-img-wrap-left'>
                            <img src={images?.cms_image_sign_up} onError={replaceImage} alt="" />
                            <NavLink to="/">
                                <div className="home-img">
                                    <FontAwesomeIcon icon={["fa", "home"]} size={50} />
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="col-lg-7 p-0">
                        <div className="right-login-wrap">
                            <div className="right-login">
                                <h2>{t("cms_text_sign_up_title")}</h2>
                                <div className="auth-img-wrap-right">
                                    <div className='contact-details-right-wrap'>
                                        <div className="Contact-with-us">
                                            <form onSubmit={signupFormik.handleSubmit}>
                                                <div className="row">


                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>First name </label>
                                                            <input type="text" onChange={signupFormik?.handleChange} value={signupFormik?.values?.firstName} placeholder="First name" id='firstName' name='firstName' className="form-control" />
                                                            <p className='form-error-message'>{signupFormik.touched.firstName &&
                                                                signupFormik.errors.firstName}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Last name </label>
                                                            <input type="test" onChange={signupFormik?.handleChange} value={signupFormik?.values?.lastName} placeholder="Last name" id='lastName' name='lastName' className="form-control" />
                                                            <p className='form-error-message'>{signupFormik.touched.lastName &&
                                                                signupFormik.errors.lastName}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Email address</label>
                                                            <input type="email" onChange={signupFormik?.handleChange} value={signupFormik?.values?.email} placeholder="Enter your email address" id='email' name='email' className="form-control" />
                                                            <p className='form-error-message'>{signupFormik.touched.email &&
                                                                signupFormik.errors.email}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Password</label>
                                                            <input type={showPassword ? "text" : "password"} onChange={signupFormik?.handleChange} value={signupFormik?.values?.password} placeholder="Enter your password" id='password' name='password' className="form-control" />
                                                            <div className='eye-btn'>
                                                                <FontAwesomeIcon icon={['fa', `${showPassword ? 'eye' : 'eye-slash'}`]} onClick={() => setShowPassword(!showPassword)} color='gray' style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <p className='form-error-message'>{signupFormik.touched.password &&
                                                                signupFormik.errors.password}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Confirm Password</label>
                                                            <input type={showConfirmPassword ? "text" : "password"} onChange={signupFormik?.handleChange} value={signupFormik?.values?.confirmPassword} placeholder="Enter confirm password" id='confirmPassword' name='confirmPassword' className="form-control" />
                                                            <div className='eye-btn'>
                                                                <FontAwesomeIcon icon={['fa', `${showConfirmPassword ? 'eye' : 'eye-slash'}`]} onClick={() => setShowConfirmPassword(!showConfirmPassword)} color='gray' style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <p className='form-error-message'>{signupFormik.touched.confirmPassword &&
                                                                signupFormik.errors.confirmPassword}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <p>Already have an account? <NavLink to="/login">Login here</NavLink></p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn">
                                                                Sign up
                                                            </button>

                                                        </div>
                                                    </div>

                                                </div>

                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    )
}

export default SignUp

import { toast } from "react-toastify";
import catchAsync from "../../utils/catchAsync";
import http from "./http";




export const refreshLoggedInUser = catchAsync(async (refreshToken) => {
    return http.post("/auth/refresh-tokens", refreshToken)
        .then((tokenData) => {
            const data = tokenData;
            if (data && data.code === 200) {
                setSession(data.data.tokens);
                return data.data.tokens;
            }
        })
        .catch((error) => {
            userLogout();
            window.location.reload();
            return false;
        });
});

/**
 * *Set Session Fot User
 * @param {object} tokens
 */
const setSession = (tokens) => {
    if (tokens) {
        const { access, refresh } = tokens;
        if (access && refresh) {
            sessionStorage.setItem("accessToken", access?.token);
            sessionStorage.setItem("refreshToken", refresh?.token);
        }
    } else {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("refreshToken");
    }
};

/**
 * *User login API.
 * @function ({{email: string, password: string}} values)
 * @returns {object}
 */



export const signIn = async (values) => {
    const data = await http.post("/auth/sign-in", values);
    if (data && data.code === 200) {
        return data;
    }
}

/**
 * Verify email before login function
 */
export const userLoginWithOtp = catchAsync(async (values) => {
    const data = await http.post("/auth/verify-login-otp", values);
    if (data && data.code === 200) {
        setSession(data.data.tokens);
    }
    return data;
});
// user logout functio
export const userLogout = () => {
    setSession(null);
    clearStorage();
}
/**
 * Clear local storage
 */
const clearStorage = () => {
    localStorage.removeItem('persist:bi-reports')
}

/**
 * Signup api function
 */
export const signUp = async (values) => {
    let bodyData = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values?.email,
        password: values?.password
    }
    const data = await http.post("/auth/sign-up", bodyData);
    if (data && data.code === 201) {
        toast.success(data.message);
        return data;
    }
}



/**
 * forgot password function
 */
export const forgotPassword = async (values) => {
    const data = await http.post("/auth/forgot-password", values)
    if (data && data.code === 200) {
        toast.success(data.message)
        return data;
    }
}

/**
 * Verify otp before change password
 */

export const verifyOtpForgotPassword = async (values) => {
    const data = await http.post("/auth/verify-reset-password-otp", values);
    if (data && data.code === 200) {
        toast.success(data.message)
        return data;
    }
}

/**
 * Verify otp before change password
 */

export const resetPassword = async (values) => {
    const data = await http.post("/auth/reset-password", values);
    if (data && data.code === 200) {
        return data;
    }
}

/**
 * Contact us section
 */

export const contactUs = async (values) => {
    let bodyData = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values?.email,
        phone: values?.phoneNumber,
        message: values?.message
    }
    let contactUs = await http.post('/contact-us', bodyData);
    if (contactUs && contactUs.code === 200) {
        toast.success(contactUs?.message)
        return contactUs;
    }
}
import React, { useEffect, useState } from 'react'
import HomeBanner from './HomeBanner'
import HomeAbout from './HomeAbout'
import HomeChooseUs from './HomeChooseUs'
import HomeService from './HomeService'
import HomeTestimonial from './HomeTestimonial'


import { dynamicDataSectionVise } from '../../../services/api/dynamicApi'
const HomeIndex = () => {
  const [images, setImages] = useState()
  useEffect(() => {
    gettingDynamicData();
  }, []);
  const gettingDynamicData = async () => {
    let payloadData = {
      "section_ids": [
        "cms_section_home_banner",
        "cms_section_home_about",
        "cms_section_home_choose",
        "cms_section_home_services",
        "cms_section_home_testimonials"
      ]
    }
    let dataGet = await dynamicDataSectionVise(payloadData);
    setImages(dataGet?.data?.pageContentImages)
  };
  return (
    <div>
      <HomeBanner images={images} />
      <HomeAbout />
      <HomeChooseUs images={images} />
      <HomeService />
      {/* <HomeTestimonial /> */}
    </div>
  )
}

export default HomeIndex
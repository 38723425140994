import React, { useEffect } from 'react'
import UserHeader from '../components/common/Headers/UserHeader'
import UserFooter from '../components/common/Footers/UserFooter'
import { resetAuth } from '../redux/reducers/authSlice'
import { userLogout } from '../services/api/authApis'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
// import { Route, Routes } from 'react-router-dom'

const UserLayout = ({ children }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    window.scrollTo(0, 0)
    useEffect(() => {
        if (!sessionStorage.getItem("refreshToken")) {
            logOutUser()
        }
    }, [])

    const logOutUser = () => {
        dispatch(resetAuth())
        userLogout()
        navigate('/login')
    }
    return (
        <div>
            <UserHeader />
            <div>
                {children}
            </div>
            <UserFooter />
        </div>
    )
}

export default UserLayout
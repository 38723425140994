import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import aboutProductsPage from '../../../imagesForShowingError/images4.jpg'



const AboutProduct = ({ images }) => {
  const { t } = useTranslation();
  const replaceImage = (error) => {
    error.target.src = aboutProductsPage;
  };
  return (
    <section className="about-product-sec">
      <div className="container">
        <div className="about-product">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-p-l">
                <img src={images?.cms_image_about_aboutProducts001} onError={replaceImage} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-p-r">
                <h2>{t("cms_text_about_aboutProducts001")} </h2>
                <p>
                  {t("cms_text_about_aboutProducts002")}
                </p>
                <p>
                  {t("cms_text_about_aboutProducts003")}
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-12">
              <div className="about-p-l">
                <img src={images?.cms_image_about_aboutProducts002} onError={replaceImage} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-p-r">
                <h2>{t("cms_text_about_aboutProducts004")} </h2>
                <p>
                  {t("cms_text_about_aboutProducts005")}
                </p>
                <p>
                  {t("cms_text_about_aboutProducts006")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};




export default AboutProduct;

import React, { useState, createRef, useEffect } from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from "react-bootstrap";

const ImagerCropper = ({ src, changeProfilePhoto, setImgFile, inputImgRef }) => {
    const [image, setImage] = useState(src);
    const [cropData, setCropData] = useState(null);
    const cropperRef = createRef();
    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
            setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            changeProfilePhoto(dataURLtoFile(cropperRef.current?.cropper.getCroppedCanvas().toDataURL(), 'profile.jpg'))
        }
    };
    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    useEffect(() => {
        return () => {
            inputImgRef.current.value = null
        }
    }, [])
    return (
        <Modal show={true}>
            <ModalHeader>
                <h3>Change Profile Image</h3>
            </ModalHeader>
            <div>
                <div style={{ width: "100%" }}>
                    <Cropper
                        aspectRatio={1 / 1}
                        ref={cropperRef}
                        style={{ height: 400, width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        guides={true}
                    />
                </div>
                <div className="image-cropper">
                    <div>
                        <div className="form-group">
                            <button type="submit" className="btn" onClick={() => setImgFile(null)}>
                                Close
                            </button>
                        </div>
                    </div>
                    <div >
                        <div className="form-group">
                            <button type="submit" className="btn" onClick={getCropData}>
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ImagerCropper
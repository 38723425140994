import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage";
import persistStore from "reduxjs-toolkit-persist/es/persistStore";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "reduxjs-toolkit-persist/es/constants";

// ReucerSlices are imported here
import authSlice from './reducers/authSlice';
import loadingSlice from './reducers/loadingSlice';


import { REDUX_PERSIST_KEY } from '../config';


const persistConfig = {
    key: REDUX_PERSIST_KEY,
    storage,
    whitelist: ["auth"],
};
const combinedReducers = persistReducer(
    persistConfig,
    combineReducers({
        auth: authSlice,
        loading: loadingSlice,
    })
)
const store = configureStore({
    reducer: combinedReducers,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };
import React from 'react'
import AuthHeader from '../components/common/Headers/AuthHeader'
import AuthFooter from '../components/common/Footers/AuthFooter'

const AuthLayout = ({ children }) => {
    return (
        <div>
            {/* <div>
                <AuthHeader />
            </div> */}
            <div>
                {children}
            </div>
            {/* <div>
                <AuthFooter />
            </div> */}
        </div>
    )
}

export default AuthLayout
import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getProfile } from "../../../services/api/myProfileApis";
import { updateUserData } from '../../../redux/reducers/authSlice';
import http from '../../../services/api/http';
import { useDispatch } from 'react-redux';

const generateState = () => {
    return uuidv4();
};

const fetchAmazonAuthInfo = async (spapi_oauth_code, selling_partner_id, state, setResponse, setUser, dispatch) => {
    console.log('Calling pseudo API...');
    try {
        const data = await http.get(`/AmazonAuthInfo?spapi_oauth_code=${spapi_oauth_code}&selling_partner_id=${selling_partner_id}&state=${state}`);
        setResponse(data);
    } catch (error) {
        console.error('Error fetching API:', error);
        setResponse({ error: error.message });
    }
    const profile = await getProfile();
    dispatch(updateUserData(profile?.data.userDetails));
    setUser(profile?.data.userDetails);
};

const AuthorizeButton = () => {
    const [response, setResponse] = useState(null);
    const [user, setUser] = useState(null);
    const dispatch = useDispatch();
    const initializationDone = useRef(false);


    // Combined effect for initialization
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        
        const initialize = async () => {
            if (initializationDone.current) return;
            initializationDone.current = true;
            
            // Handle OAuth callback if present
            if (urlParams.has('spapi_oauth_code')) {
                const spapi_oauth_code = urlParams.get('spapi_oauth_code');
                const selling_partner_id = urlParams.get('selling_partner_id');
                const state = urlParams.get('state');
                await fetchAmazonAuthInfo(spapi_oauth_code, selling_partner_id, state, setResponse, setUser, dispatch);
            } else {
                // Just fetch profile if no OAuth callback
                const profile = await getProfile();
                dispatch(updateUserData(profile?.data.userDetails));
                setUser(profile?.data.userDetails);
            }
        };

        initialize();
    }, []); // Empty dependency array - runs once on mount

    const handleAuthorize = () => {
        const applicationId = 'amzn1.sp.solution.5ef463c6-7247-4d17-85dd-4e51380da8b0';
        const state = generateState();
        localStorage.setItem('state', state);
        const redirectUri = 'https://bireports.today/amazon_spapi_auth';

        const oauthUri = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${applicationId}&state=${state}&redirect_uri=${redirectUri}&version=beta`;
        window.location.href = oauthUri;
    };

    const renderResponse = () => {
        if (response) {
            if (response.error) {
                return <div>Error: {response.error}</div>;
            }
            return <div>Added authentication for {response?.newSellingPartnerId}</div>;
        }
        return null;
    };

    return (
        <section className="pr-details common-padd pt-0">
            <div className="container">
                <div className="pr-details-box">
                    <h2>Amazon Authorization</h2>
                    <div className="contact-details-right-wrap">
                        <div className="Contact-with-us">
                            {renderResponse()}
                            <button onClick={handleAuthorize} className="btn">
                                Authorize Or Reauthorize a Amazon Marketplace
                            </button>
                            {user && (
                                <div>
                                    <h4>Selling Partner IDs:</h4>
                                    <ul>
                                        {user.selling_partner_ids.map((id, index) => (
                                            <li key={index}>{id}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AuthorizeButton;



import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { verifyOtpForgotPassword } from '../../../services/api/authApis';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { dynamicDataSectionVise } from '../../../services/api/dynamicApi';
import otpPageImage from '../../../imagesForShowingError/images6.png'


const PasswordVerifyIndex = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [otp, setOtp] = useState(null)
    const { t } = useTranslation();
    const [error, setError] = useState('')
    const verifyOtp = async (e) => {
        e.preventDefault()
        if (!otp || otp?.length < 6) {
            toast.error('Otp must be at least 6 numbers')
        }
        else {
            let bodyData = {
                email: location?.state?.email,
                otp: Number(otp)
            }
            let data = await verifyOtpForgotPassword(bodyData)
            if (data) {
                navigate('/resetPassword', { state: { otp: otp } })
            }
        }
    }
    const backToEmail = () => {
        navigate('/forgotpassword')
    }
    const hanldeOtp = (e) => {
        if (Number(e)) {
            setOtp(e)
            setError('')
        }
        else {
            setError('OTP must be number')
        }
    }

    useEffect(() => {
        if (!location?.state?.email) {
            navigate('/login')
        }
    }, [location?.state?.email])
    const [images, setImages] = useState()
    useEffect(() => {
        gettingDynamicData();
    }, []);
    const gettingDynamicData = async () => {
        let payloadData = {
            "section_ids": [
                "cms_section_verify_email",

            ]
        }
        let dataGet = await dynamicDataSectionVise(payloadData);
        setImages(dataGet?.data?.pageContentImages)
    };
    const replaceImage = (error) => {
        error.target.src = otpPageImage;
    };
    return (
        <div className='loginwrap verifyemail'>
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-lg-5 p-0">
                        <div className='auth-img-wrap-left'>
                            <img src={images?.cms_image_verify_email} alt="" onError={replaceImage} />
                            <NavLink to="/">
                                <div className="home-img">
                                    <FontAwesomeIcon icon={["fa", "home"]} size={50} />
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="col-lg-7 p-0">
                        <div className="right-login-wrap">
                            <div className="right-login">
                                <h2>{t("cms_text_verify_email_title")}</h2>
                                <p>Email:- {location?.state?.email} <span onClick={backToEmail}>Change email</span></p>
                                <div className="auth-img-wrap-right">
                                    <div className='contact-details-right-wrap'>
                                        <div className="Contact-with-us">
                                            <form onSubmit={verifyOtp}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label >Verification code</label>
                                                            <OtpInput
                                                                inputType='text'
                                                                value={otp}
                                                                onChange={hanldeOtp}
                                                                numInputs={6}
                                                                containerStyle={'otp-main'}
                                                                renderSeparator={<span className='gap-span'> </span>}
                                                                renderInput={(props) => <input {...props} />}
                                                            />
                                                            <p className='form-error-message'>{error}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn">
                                                                Verify Now
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};





export default PasswordVerifyIndex;

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function Protected({ isSignedIn, children }) {
    const { pathname, search } = useLocation();

    return isSignedIn 
        ? children 
        : <Navigate to={`/login${search}`} replace state={{ from: pathname }} />;
}

export default Protected;

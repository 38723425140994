import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { forgotpasswordFromValidator } from '../../../services/formValidator/authFormValidator';
import { resetPassword } from '../../../services/api/authApis';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { dynamicDataSectionVise } from '../../../services/api/dynamicApi';
import resetPassPageImage from '../../../imagesForShowingError/images2.jpg'


const ResetPasswordIndex = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const { t } = useTranslation();
    const forgotpasswordFormik = useFormik({
        initialValues: forgotpasswordFromValidator?.initialValues,
        validationSchema: forgotpasswordFromValidator?.validationSchema,
        onSubmit: async (values) => {
            if (!location?.state?.otp) {
                navigate('/login')
            }
            else {
                let bodyData = {
                    otp: Number(location?.state?.otp),
                    password: values?.password
                }
                const data = await resetPassword(bodyData)
                if (data) {
                    toast.success(data.message)
                    navigate('/login')
                }
            }
        }
    })

    useEffect(() => {
        if (!location?.state?.otp) {
            navigate('/login')
        }
    }, [location?.state?.otp])
    const [images, setImages] = useState()
    useEffect(() => {
        gettingDynamicData();
    }, []);
    const gettingDynamicData = async () => {
        let payloadData = {
            "section_ids": [
                "cms_section_reset_password",

            ]
        }
        let dataGet = await dynamicDataSectionVise(payloadData);
        setImages(dataGet?.data?.pageContentImages)
    };
    const replaceImage = (error) => {
        error.target.src = resetPassPageImage;
    };
    return (
        <div className='loginwrap'>
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-lg-5 p-0">
                        <div className='auth-img-wrap-left'>
                            <img src={images?.cms_image_reset_password} onError={replaceImage} alt="" />
                            <NavLink to="/">
                                <div className="home-img">
                                    <FontAwesomeIcon icon={["fas", "home"]} />
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="col-lg-7 p-0">
                        <div className="right-login-wrap">
                            <div className="right-login">
                                <h2>{t("cms_text_reset_password_title")}</h2>
                                <div className="auth-img-wrap-right">
                                    <div className='contact-details-right-wrap'>
                                        <div className="Contact-with-us">
                                            <form onSubmit={forgotpasswordFormik?.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Password</label>
                                                            <input onChange={forgotpasswordFormik?.handleChange} value={forgotpasswordFormik?.password} type={showPassword ? "text" : "password"} placeholder="Enter your password" id='password' name='password' className="form-control" />
                                                            <div className='eye-btn'>
                                                                <FontAwesomeIcon icon={['fa', `${showPassword ? 'eye' : 'eye-slash'}`]} onClick={() => setShowPassword(!showPassword)} color='gray' style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <p className='form-error-message'>{forgotpasswordFormik.touched.password &&
                                                                forgotpasswordFormik.errors.password}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Confirm Password</label>
                                                            <input onChange={forgotpasswordFormik?.handleChange} value={forgotpasswordFormik?.confirmPassword} type={showConfirmPassword ? "text" : "password"} placeholder="Enter your password" id='confirmPassword' name='confirmPassword' className="form-control" />
                                                            <div className='eye-btn'>
                                                                <FontAwesomeIcon icon={['fa', `${showConfirmPassword ? 'eye' : 'eye-slash'}`]} onClick={() => setShowConfirmPassword(!showConfirmPassword)} color='gray' style={{ cursor: 'pointer' }} />
                                                            </div>
                                                            <p className='form-error-message'>{forgotpasswordFormik.touched.confirmPassword &&
                                                                forgotpasswordFormik.errors.confirmPassword}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn">
                                                                save change
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};





export default ResetPasswordIndex;

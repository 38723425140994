import { toast } from "react-toastify";
import http from "./http";

export const deleteAccount = async () => {
    const data = await http.delete("/profile/delete-account");
    if (data && data.code === 200) {
        return data;
    }
}

export const updateProfileImag = async (bodyData) => {
    const data = await http.patch("/profile/update-profile-image", bodyData);
    if (data && data.code === 200) {
        toast.success(data.message)
        return data;
    }
}

export const updateProfile = async (bodyData) => {
    const data = await http.patch("/profile/update-profile-details", bodyData);
    if (data && data.code === 200) {
        toast.success(data.message)
        return data;
    }
}

export const getProfile = async () => {
    const data = await http.get("/profile/get-profile-details");
    if (data && data.code === 200) {
        return data;
    }
}


export const changePassword = async (values) => {
    const data = await http.patch("/profile/change-password", values);
    if (data && data.code === 200) {
        return data;
    }
}
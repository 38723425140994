import React from 'react';
import { useTranslation } from 'react-i18next';



const ServiceCompanies = () => {
  const { t } = useTranslation();
  return (
    <section className="Energy-Companies position-relative  common-padd">
      <div className="container">
        <div className="ener-gray ">
          <h4>
            {t("cms_text_service_serviceCompanies001")}
          </h4>
          <p>
            {t("cms_text_service_serviceCompanies002")}

          </p>
        </div>
      </div>
    </section>
  );
};



export default ServiceCompanies;

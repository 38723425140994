import * as Yup from "yup";


export const myProfileValidator = (user, type) => {
    if (type === "initialValues") {
        let initialValues = {
            first_name: user?.first_name ?? "",
            last_name: user?.last_name ?? "",
            email: user?.email ?? "",
            about: user?.about ?? ""
        }
        return initialValues
    }
    if (type === "validationSchema") {
        let validationSchema = Yup.object().shape({
            first_name: Yup.string()
                .required("Name field can not be empty")
                .min(3, "Must be 3 characters")
                .trim('The contact name cannot include leading and trailing spaces')
                .matches(/^[a-zA-Z0-9\s]+$/, 'Only uppercase letters, lowercase letters, and numbers are allowed.')
                .max(20, "Max can be 30 characters"),
            last_name: Yup.string()
                .required("Name field can not be empty")
                .min(3, "Must be 3 characters")
                .trim('The contact name cannot include leading and trailing spaces')
                .matches(/^[a-zA-Z0-9\s]+$/, 'Only uppercase letters, lowercase letters, and numbers are allowed.')
                .max(20, "Max can be 30 characters"),
            email: Yup.string()
                .email("Invalid email address")
                .required("Email field cannot be empty"),
            about: Yup.string()
                .min(5, "Must be 3 characters")
                .max(150, "Max can be 150 characters"),
        })
        return validationSchema
    }
}


/**
 * changePassword form validator 
 */
export const changePasswordFromValidator = {
    initialValues: {
        oldPassword: "",
        password: "",
        confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
        oldPassword: Yup.string()
            .required("Old password field is Required")
            .matches(/^(?!.*\s)/, "Spaces are not allowed")
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
            ),
        password: Yup.string()
            .required("Password field is Required")
            .matches(/^(?!.*\s)/, "Spaces are not allowed")
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Password must be eight characters including atleast one uppercase letter, one lowercase letter, one digit and one special character!"
            ),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), ""], "Password must match")
            .required("Confirm password field is required"),
    }),
}

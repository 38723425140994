import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import contactBannerImage from '../../../imagesForShowingError/contact-banner.jpg'



const ContactBanner = ({ images }) => {
  const { t } = useTranslation();
  const [srcImage, setSrcImage] = useState()
  useEffect(() => {
    setSrcImage(images?.cms_image_contact_banner001)
  }, [images])
  const replaceImage = (error) => {
    error.target.src = contactBannerImage;
  };
  return (
    <section className="home-banner-section inner-banner position-relative">
      <div className="banner-img-wrap">
        <img src={srcImage} alt="" onError={replaceImage} />
      </div>
      <div className="banner-text-wrap">
        <div className="container">
          <div className="inner-banner-text">
            <h1>{t("cms_text_contact_banner001")}</h1>
          </div>
        </div>
      </div>
    </section>
  );
};



export default ContactBanner;

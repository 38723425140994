import * as Yup from "yup";

// Phone number validation regexp
const phoneRegExp = "^[0-9]{10}$";

export const contactUsFormValidator = {
  initialValues: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  },
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(20, "Max can be 30 characters"),
    lastName: Yup.string()
      .required("Name field can not be empty")
      .min(3, "Must be 3 characters")
      .max(20, "Max can be 30 characters"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email field cannot be empty"),
    phoneNumber: Yup.string()
      .required("Phone field can not be empty")
      .matches(phoneRegExp, "Phone number is not valid"),
    message: Yup.string()
      .required("Message field can not be empty")
      .min(5, "Must be 3 characters")
      .max(150, "Max can be 150 characters"),
  }),
};

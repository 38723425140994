import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';



const HomeAbout = () => {
  const { t } = useTranslation();

  return (
    <section className="about-sec position-relative common-padd">
      <div className="container">
        <div className="sec-heading">
          <h5>{t("cms_text_home_about001")}</h5>
          <h2>
            {t("cms_text_home_about002")}{" "}
            <span className="sky-text">{t("cms_text_home_about003")}</span>
          </h2>
          <p>
            {t("cms_text_home_about004")}
          </p>
        </div>

        <NavLink to="/about" className="round-btn">  {t("cms_text_home_about005")}</NavLink>
      </div>
    </section>
  );
};





export default HomeAbout;

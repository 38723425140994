import AuthLayout from "../layouts/AuthLayout";
import UserLayout from "../layouts/UserLayout";
import VisitorLayout from "../layouts/VisitorLayout";
import MydetailsIndex from "../pages/User/myDetails";
import AboutIndex from "../pages/Visitor/About";
import ContactIndex from "../pages/Visitor/Contact";
import HomeIndex from "../pages/Visitor/Home";
import PrivacyPolicyIndex from "../pages/Visitor/PrivacyPolicy";
import ServicesIndex from "../pages/Visitor/Services";
import ServicesDetailsIndex from "../pages/Visitor/ServicesDetails";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import VerifyEmailIndex from "../pages/auth/VerifyEmail";
import PasswordVerifyIndex from "../pages/auth/PasswordVerify";
import ResetPasswordIndex from "../pages/auth/ResetPassword";
import NoContentPage from "../components/common/NoContentPage/NoContentPage";
import ChangePassword from "../pages/User/changePassword";
import AuthorizeButton from "../pages/User/amazonToken";
import TermsAndConditions from "../pages/Visitor/TermsConditions";

const withLayout = (Layout, Component) => <Layout>{Component}</Layout>;

const routes = [
  // Auth routes
  {
    path: "/login",
    element: withLayout(AuthLayout, <Login />),
    protected: false,
  },
  {
    path: "/signup",
    element: withLayout(AuthLayout, <SignUp />),
    protected: false,
  },
  {
    path: "/forgotpassword",
    element: withLayout(AuthLayout, <ForgotPassword />),
    protected: false,
  },
  {
    path: "/verifyemail",
    element: withLayout(AuthLayout, <VerifyEmailIndex />),
    protected: false,
  },
  {
    path: "/passwordverify",
    element: withLayout(AuthLayout, <PasswordVerifyIndex />),
    protected: false,
  },
  {
    path: "/resetPassword",
    element: withLayout(AuthLayout, <ResetPasswordIndex />),
    protected: false,
  },
  // Visitor routes
  {
    path: "/",
    element: withLayout(VisitorLayout, <HomeIndex />),
    protected: false,
  },
  {
    path: "/about",
    element: withLayout(VisitorLayout, <AboutIndex />),
    protected: false,
  },
  {
    path: "/services",
    element: withLayout(VisitorLayout, <ServicesIndex />),
    protected: false,
  },
  {
    path: "/services-details",
    element: withLayout(VisitorLayout, <ServicesDetailsIndex />),
    protected: false,
  },
  {
    path: "/contactus",
    element: withLayout(VisitorLayout, <ContactIndex />),
    protected: false,
  },
  {
    path: "/privacypolicy",
    element: withLayout(VisitorLayout, <PrivacyPolicyIndex />),
    protected: false,
  },
  {
    path: "/terms_conditions",
    element: withLayout(VisitorLayout, <TermsAndConditions />),
    protected: false,
  },
  // User routes
  {
    path: "/my-details",
    element: withLayout(UserLayout, <MydetailsIndex />),
    protected: true,
    role: ["user"],
  },
  {
    path: "/amazon_spapi_auth",
    element: withLayout(UserLayout, <AuthorizeButton />),
    protected: true,
    role: ["user"],
  },
  {
    path: "/change-password",
    element: withLayout(UserLayout, <ChangePassword />),
    protected: true,
    role: ["user"],
  },
  {
    path: "*",
    element: <NoContentPage />,
  },
];

export default routes;

import React, { useEffect } from 'react';
import ServiceDetailsBanner from './ServiceDetailsBanner';
import ServiceDetailsCompanies from './ServiceDetailsCompanies';
import ServiceDetails from './ServiceDetails';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ServicesDetailsIndex = () => {
    const location = useLocation()
    const { t } = useTranslation();

    return (
        // <div dangerouslySetInnerHTML={{ __html: `${t(location?.state?.details)}` }}>
        //    <ServiceDetailsBanner />
        //    <ServiceDetailsCompanies />
        //    <ServiceDetails />

        // </div>
        <>
            <section className='content-page common-padd' >
                <div className='container' dangerouslySetInnerHTML={{ __html: `${t(location?.state?.details)}` }}>
                </div>
            </section>
        </>
    );
};


export default ServicesDetailsIndex;


import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getServices } from '../../../services/api/servicesApi';


const ServiceService = () => {
  const { t } = useTranslation();
  const [allServicesData, setAllServicesData] = useState([]);

  useEffect(() => {
    (async () => {
      let data = await getServices();
      setAllServicesData(data?.data?.services);
    })();
    return () => setAllServicesData();
  }, []);
  return (
    <section className="service-sec service-page common-padd">
      <div className="container">

        <div className="sec-heading text-center">
          {/* <h5>{t("cms_text_service_serviceServices001")}</h5> */}
          {/* <h2>
            {" "}
            <strong>{t("cms_text_service_serviceServices002")}</strong> {t("cms_text_service_serviceServices003")}
          </h2> */}
          <p>
            {t("cms_text_service_serviceServices004")}
          </p>
        </div>

        <div className="service-row">
          <div className="row">
            {allServicesData && allServicesData.map((val, idx) => {
              return (
                <>
                  <div className="col-md-6">
                    <div className="service-col-wrap">
                      <div className="service-col-img">
                        <img src={val?.image} alt="" />
                      </div>
                      <div className="service-col-content">
                        <h4>
                          {t(val?.title)}
                          <br />

                        </h4>
                        <Link to={'/services-details'}
                          state={{ details: val?.details }}>

                          <p className='read-more'>  {t(val?.link)}
                            <span className="right-arrow-i">


                              <FontAwesomeIcon icon={["fas", "fa-arrow-right"]} />

                            </span>
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}

            {/* <div className="col-md-6">
              <div className="service-col-wrap">
                <div className="service-col-img">
                  <img src="images/images2.jpg" alt="" />
                </div>
                <div className="service-col-content">
                  <h4>
                    {t("cms_text_service_serviceServices007")}
                    <br />
                    {t("cms_text_service_serviceServices008")}
                  </h4>
                  <NavLink to='/services-details'>

                    <p className='read-more'>  {t("cms_text_service_serviceServices009")}
                      <span className="right-arrow-i">


                        <FontAwesomeIcon icon={["fas", "fa-arrow-right"]} />

                      </span>
                    </p>
                  </NavLink>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6">
              <div className="service-col-wrap">
                <div className="service-col-img">
                  <img src="images/images3.jpg" alt="" />
                </div>
                <div className="service-col-content">
                  <h4>{t("cms_text_service_serviceServices010")}</h4>
                  <NavLink to='/services-details'>

                    <p className='read-more'>  {t("cms_text_service_serviceServices011")}
                      <span className="right-arrow-i">


                        <FontAwesomeIcon icon={["fas", "fa-arrow-right"]} />

                      </span>
                    </p>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-col-wrap">
                <div className="service-col-img">
                  <img src="images/images4.jpg" alt="" />
                </div>
                <div className="service-col-content">
                  <h4>{t("cms_text_service_serviceServices012")}</h4>
                  <NavLink to='/services-details'>

                    <p className='read-more'>  {t("cms_text_service_serviceServices013")}
                      <span className="right-arrow-i">


                        <FontAwesomeIcon icon={["fas", "fa-arrow-right"]} />

                      </span>
                    </p>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-col-wrap">
                <div className="service-col-img">
                  <img src="images/images5.jpg" alt="" />
                </div>
                <div className="service-col-content">
                  <h4>{t("cms_text_service_serviceServices014")}</h4>
                  <NavLink to='/services-details'>

                    <p className='read-more'>  {t("cms_text_service_serviceServices015")}
                      <span className="right-arrow-i">


                        <FontAwesomeIcon icon={["fas", "fa-arrow-right"]} />

                      </span>
                    </p>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-col-wrap">
                <div className="service-col-img">
                  <img src="images/images6.png" alt="" />
                </div>
                <div className="service-col-content">
                  <h4>{t("cms_text_service_serviceServices016")}</h4>
                  <NavLink to='/services-details'>

                    <p className='read-more'>  {t("cms_text_service_serviceServices017")}
                      <span className="right-arrow-i">


                        <FontAwesomeIcon icon={["fas", "fa-arrow-right"]} />

                      </span>
                    </p>
                  </NavLink>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="text-center mt-5">
            <NavLink to="/" className="round-btn"> {t("cms_text_service_serviceServices018")}</NavLink>
          </div> */}
        </div>
      </div>
    </section>

  );
};



export default ServiceService;

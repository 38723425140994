import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { forgotPassword } from '../../../services/api/authApis'
import { toast } from 'react-toastify'
import { dynamicDataSectionVise } from '../../../services/api/dynamicApi'
import { useTranslation } from 'react-i18next'
import forgotPassImage from '../../../imagesForShowingError/images2.jpg'

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const { t } = useTranslation();
    const verifyEmail = async (e) => {
        e.preventDefault()
        if (!!email) {
            let values = {
                email: email
            }
            let data = await forgotPassword(values)
            if (data) {
                navigate('/passwordverify', { state: { email: email } })
            }
        }
        else {
            toast.error('Please enter a valide email');
        }
    }
    const backToLogin = () => {
        navigate('/login')
    }
    const [images, setImages] = useState()
    useEffect(() => {
        gettingDynamicData();
    }, []);
    const gettingDynamicData = async () => {
        let payloadData = {
            "section_ids": [
                "cms_section_forgot_password",

            ]
        }
        let dataGet = await dynamicDataSectionVise(payloadData);
        setImages(dataGet?.data?.pageContentImages)
    };
    const replaceImage = (error) => {
        error.target.src = forgotPassImage;
    };
    return (
        <div className='loginwrap auth-forget-psw'>
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-lg-5 p-0">
                        <div className='auth-img-wrap-left'>
                            <img src={images?.cms_image_forgot_password} onError={replaceImage} alt="" />
                            <NavLink to="/">
                                <div className="home-img">
                                    <FontAwesomeIcon icon={["fa", "home"]} size={50} />
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="col-lg-7 p-0">
                        <div className="right-login-wrap">
                            <div className="right-login">
                                <h2>{t("cms_text_forgot_password_title")}</h2>
                                <div className="auth-img-wrap-right">
                                    <div className='contact-details-right-wrap'>
                                        <div className="Contact-with-us">
                                            <form onSubmit={verifyEmail}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Email address</label>
                                                            <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Enter your email address" id='email' name='email' className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn">
                                                                send reset instructions
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <p className='back-to-login'>Back to <span onClick={backToLogin}>login</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { changePasswordFromValidator } from '../../../services/formValidator/myProfileFormValidator'
import { changePassword } from '../../../services/api/myProfileApis'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ChangePassword = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const changePasswordFormik = useFormik({
        initialValues: changePasswordFromValidator?.initialValues,
        validationSchema: changePasswordFromValidator?.validationSchema,
        onSubmit: async (values) => {
            let bodyData = {
                old_password: values.oldPassword,
                new_password: values?.password
            }
            const data = await changePassword(bodyData)
            if (data) {
                toast.success(data.message)
                navigate('/my-details')
            }
        }
    })
    return (
        <div className='change-password'>
            <h2>Change password</h2>
            <div className="Contact-with-us">
                <form onSubmit={changePasswordFormik?.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label>Old Password</label>
                                <input onChange={changePasswordFormik?.handleChange} value={changePasswordFormik?.password} type={showPassword ? "text" : "password"} placeholder="Enter your old password" id='oldPassword' name='oldPassword' className="form-control" />
                                <div className='eye-btn'>
                                    <FontAwesomeIcon icon={['fa', `${showPassword ? 'eye' : 'eye-slash'}`]} onClick={() => setShowPassword(!showPassword)} color='gray' style={{ cursor: 'pointer' }} />
                                </div>
                                <p className='form-error-message'>{changePasswordFormik.touched.oldPassword &&
                                    changePasswordFormik.errors.oldPassword}</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label>New Password</label>
                                <input onChange={changePasswordFormik?.handleChange} value={changePasswordFormik?.password} type={showNewPassword ? "text" : "password"} placeholder="Enter your password" id='password' name='password' className="form-control" />
                                <div className='eye-btn'>
                                    <FontAwesomeIcon icon={['fa', `${showNewPassword ? 'eye' : 'eye-slash'}`]} onClick={() => setShowNewPassword(!showNewPassword)} color='gray' style={{ cursor: 'pointer' }} />
                                </div>
                                <p className='form-error-message'>{changePasswordFormik.touched.password &&
                                    changePasswordFormik.errors.password}</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label>Confirm Password</label>
                                <input onChange={changePasswordFormik?.handleChange} value={changePasswordFormik?.confirmPassword} type={showConfirmPassword ? "text" : "password"} placeholder="Enter your password" id='confirmPassword' name='confirmPassword' className="form-control" />
                                <div className='eye-btn'>
                                    <FontAwesomeIcon icon={['fa', `${showConfirmPassword ? 'eye' : 'eye-slash'}`]} onClick={() => setShowConfirmPassword(!showConfirmPassword)} color='gray' style={{ cursor: 'pointer' }} />
                                </div>
                                <p className='form-error-message'>{changePasswordFormik.touched.confirmPassword &&
                                    changePasswordFormik.errors.confirmPassword}</p>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <button type="submit" className="btn">
                                    save change
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChangePassword
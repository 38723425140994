import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom'

const VisitorHeader = () => {
    const { t } = useTranslation();
    const location = useLocation()
    const [scrollPosition, setScrollPosition] = useState(0);
    const [openHam, setOpenham] = useState(false)

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    }
    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <header className={`main-header ${scrollPosition > 0 && 'fixed'}`}>
            <div className="container">
                <div className="header-row">
                    <div className="logo">

                        <NavLink to="/"> <img src="/images/logo.png" alt="" /> </NavLink>
                    </div>
                    <div className="hdr-rt">
                        <div className="main-menu" style={{ transform: openHam ? 'translateX(0px)' : '' }}>
                            <div className="nav_close" onClick={() => setOpenham(false)}>
                                <FontAwesomeIcon icon={["far", "fa-times-circle"]} color='white' />
                            </div>
                            <div className="menu-main-list">
                                <ul>
                                    <li className={`${location?.pathname === '/' && 'current-menu-item'}`}>
                                        <NavLink to="/">{t("cms_text_header_visitorHome")}</NavLink>
                                    </li>
                                    <li className={`${location?.pathname === '/about' && 'current-menu-item'}`}>
                                        <NavLink to="/about">{t("cms_text_header_visitorAbout")}</NavLink>
                                    </li>
                                    <li className={`${location?.pathname === '/services' && 'current-menu-item'}`}>
                                        <NavLink to="/services">{t("cms_text_header_visitorServices")}</NavLink>
                                    </li>
                                    <li className={`${location?.pathname === '/contactus' && 'current-menu-item'}`}>
                                        <NavLink to="/contactus">{t("cms_text_header_visitorContactUs")}</NavLink>

                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className="log-div for-m">
                            <ul>
                                <li>
                                    <NavLink to="/login">
                                        <button>
                                            <span className="user-icon">
                                                <FontAwesomeIcon icon={["fas", "user"]} />
                                            </span>
                                            {t("cms_text_header_visitorLogIn")}
                                        </button>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/signup">
                                        <button>
                                            <span className="user-icon">
                                                <FontAwesomeIcon icon={["fas", "user"]} />
                                            </span>
                                            {t("cms_text_header_visitorSignUp")}
                                        </button>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>


                        <div className="nav_btn" onClick={() => setOpenham(true)}>
                            <FontAwesomeIcon icon={["fas", "bars"]} color='black' />

                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default VisitorHeader
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { contactUsFormValidator } from '../../../services/formValidator/contactUsFormValidator';
import { useTranslation } from 'react-i18next';
import { contactUs } from '../../../services/api/authApis';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { toast } from 'react-toastify';




const ContactDetails = () => {
    const { t } = useTranslation();
    const [captcha, setCaptcha] = useState("")
    const contactUsFormik = useFormik({
        initialValues: contactUsFormValidator?.initialValues,
        validationSchema: contactUsFormValidator?.validationSchema,
        onSubmit: (values) => {
            if (validateCaptcha(captcha)) {
                contactUs(values)
                contactUsFormik?.resetForm()
                setCaptcha("")
                loadCaptchaEnginge(6)

            }
            else {
                toast.error('Please enter a valid captcha')
                loadCaptchaEnginge(6)

            }
        }
    })
    useEffect(() => {
        loadCaptchaEnginge(6)
    }, [])
    return (
        <section className='position-relative contact-details  common-padd'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-12'>
                        <div className='contact-details-col-wrap'>
                            <div className="sec-heading">
                                <h5>{t("cms_text_contact_details001")}</h5>
                                <h2>
                                    {" "}
                                    <strong>{t("cms_text_contact_details002")} </strong>  <span className="sky-text">{t("cms_text_contact_details003")}</span>
                                </h2>
                                <p>
                                    {t("cms_text_contact_details004")}
                                </p>
                                <div className="contact-social-link">
                                    <ul>

                                        <li>
                                            <a href={`tel:${t("cms_text_contact_details006")}`}>
                                                <div className='call-round'>
                                                    <FontAwesomeIcon icon={["fa", "fa-phone-alt"]} />
                                                </div>
                                                <p><b>{t("cms_text_contact_details005")}</b>{t("cms_text_contact_details006")}</p>
                                            </a>

                                        </li>
                                        <li>
                                            <a href={`mailto:${t("cms_text_contact_details008")}`}>
                                                <div className='call-round'>
                                                    <FontAwesomeIcon icon={["fa", "fa-envelope"]} />
                                                </div>
                                                <p><b>{t("cms_text_contact_details007")}</b>{t("cms_text_contact_details008")}</p>
                                            </a>


                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12'>
                        <div className='contact-details-right-wrap'>
                            <div className="Contact-with-us">
                                <h4>{t("cms_text_contact_details009")}</h4>
                                <form onSubmit={contactUsFormik?.handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>{t("cms_text_contact_details010")}</label>
                                                <input type="text" onChange={contactUsFormik?.handleChange} value={contactUsFormik?.values?.firstName} placeholder="First name" id='firstName' name='firstName' className="form-control" />
                                                <p className='form-error-message'>{contactUsFormik.touched.firstName &&
                                                    contactUsFormik.errors.firstName}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>{t("cms_text_contact_details011")}</label>
                                                <input type="text" onChange={contactUsFormik?.handleChange} value={contactUsFormik?.values?.lastName} placeholder="Last name" id='lastName' name='lastName' className="form-control" />
                                                <p className='form-error-message'>{contactUsFormik.touched.lastName &&
                                                    contactUsFormik.errors.lastName}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>{t("cms_text_contact_details012")}</label>
                                                <input type="email" onChange={contactUsFormik?.handleChange} value={contactUsFormik?.values?.email} placeholder="Email address" id='email' name='email' className="form-control" />
                                                <p className='form-error-message'>{contactUsFormik.touched.email &&
                                                    contactUsFormik.errors.email}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label>{t("cms_text_contact_details013")}</label>
                                                <input type="text" onChange={contactUsFormik?.handleChange} value={contactUsFormik?.values?.phoneNumber} placeholder="Phone no." id='phoneNumber' name='phoneNumber' className="form-control" />
                                                <p className='form-error-message'>{contactUsFormik.touched.phoneNumber &&
                                                    contactUsFormik.errors.phoneNumber}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label>{t("cms_text_contact_details014")}</label>
                                                <textarea onChange={contactUsFormik?.handleChange} value={contactUsFormik?.values?.message} placeholder="Write something" name="message" id="message" className="form-control"></textarea>
                                                <p className='form-error-message'>{contactUsFormik.touched.message &&
                                                    contactUsFormik.errors.message}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label>{t("cms_text_contact_details015")}</label>
                                                <div className="r-cap">
                                                    {/* <img src="images/r-cap.png" alt="" /> */}
                                                    <LoadCanvasTemplate />
                                                </div>
                                                <input type="text" placeholder="Type the captcha" id='type' name='type' className="form-control" value={captcha} onChange={(e) => setCaptcha(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <button type="submit" className="btn">
                                                    Submit
                                                </button>

                                            </div>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </section>

    );
};




export default ContactDetails;

import { memo, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { v4 } from "uuid";
import Protected from "./ProtectedRoute";
import { useSelector } from "react-redux";

const RouteIdentifier = ({routes}) => {
    const {user}=useSelector((state)=>state.auth)
    const fallback = <div className="loading" />
    return (
        <Suspense fallback={fallback}>
            <Routes>
                {routes.map((route, index) =>
                    route.protected ? (
                        <Route
                            key={`r.${index}-${v4()}`}
                            path={route.path}
                            element={
                                <Protected isSignedIn={route.role.includes(user?.role)}>
                                    {route.element}
                                </Protected>
                            }
                        />
                    ) : (
                        <Route
                            key={`${index}-${v4()}`}
                            path={route.path}
                            element={route.element}
                        />
                    )
                )}
            </Routes>
        </Suspense>
    )
};

export default memo(RouteIdentifier);

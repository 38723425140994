import React, { useEffect, useState } from 'react'
import ContactBanner from './ContactBanner'
import ContactDetails from './ContactDetails'
import { dynamicDataSectionVise } from '../../../services/api/dynamicApi'

const ContactIndex = () => {
  const [images, setImages] = useState()
  useEffect(() => {
    gettingDynamicData();
  }, []);
  const gettingDynamicData = async () => {
    let payloadData = {
      "section_ids": [
        "cms_section_contact_details",
        "cms_section_contact_banner"

      ]
    }
    let dataGet = await dynamicDataSectionVise(payloadData);
    setImages(dataGet?.data?.pageContentImages)
  };
  return (
    <div>
      <ContactBanner images={images} />
      <ContactDetails />
    </div>
  )
}

export default ContactIndex